import * as React from "react"

import classNames from 'classnames/bind'
import * as s from './projectcredits.module.scss'

const cn = classNames.bind(s)

const ProjectCredits = (props) => {

  let credits = props.credits;

  if(credits) {

    let roles = credits.map(
      (credit, i) => {

        let role = credit.credit_role;

        if(i !== credits.length - 1) {
          role += ", ";
        }

        return role
      }
    )

    credits = (
        <div className={cn('credit_row')}>
            <div className={cn('credit_name')}>My Roles</div>
            <div className={cn('credit_role')}>{roles}</div>
        </div>
    )

  }
  else {
    credits = (<div></div>)
  }

  return (
    <div className={cn('credits')}>
      {credits}
    </div>
  )
}

export default ProjectCredits
