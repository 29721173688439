import { graphql } from 'gatsby'

import ProjectPage from 'views/project/ProjectPage'

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        tag
        header_alt
        header {
          childImageSharp {
            gatsbyImageData
          }
        }
        intro
        roles {
          credit_role
        }
        project_images {
          alt
          project_videoid
          project_image {
						childImageSharp {
							gatsbyImageData
            }
          }
        }
      }
    }
  }
`;



export default ProjectPage
