import * as React from "react"
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import * as s from './projectimage.module.scss'

import Image from 'components/ui/image/Image'

const cn = classNames.bind(s)

const ProjectImage = (props) => {
  return (
    <Image
      image={props.image}
      alt={props.alt}
      className={cn('project_image')}
    />
  )
}

Image.propTypes = {
  image : PropTypes.object.isRequired,
  alt : PropTypes.string,
  className : PropTypes.string
}

export default ProjectImage
