import React, { useEffect } from 'react';
import { motion, useViewportScroll, useTransform } from "framer-motion";

import {navigate} from 'gatsby'

import Container from 'components/ui/layout/Container'
import Layout from 'components/ui/layout/Layout'
import Image from 'components/ui/image/Image'

import ProjectImage from './components/ProjectImage.js'
import ProjectVideo from './components/ProjectVideo.js'
import ProjectCredits from './components/ProjectCredits.js'

import classNames from 'classnames/bind'
import * as s from './projectpage.module.scss'

const cn = classNames.bind(s);

// markup
const ProjectPage = ({data, children, transitionStatus, entry, exit, location, ...props}) => {

  const projectDetails = data.mdx.frontmatter;
  const projectDescription = data.mdx.frontmatter.intro

  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0.9, 1], [0, 1]);

  let projectImages = [];

  if(projectDetails.project_images) {

    projectImages = projectDetails.project_images.map(
      (imageNode, i) => {

        let projectPiece = (
          <ProjectImage
            image={imageNode.project_image.childImageSharp.gatsbyImageData}
            alt={imageNode.alt}
            key={projectDetails.title + '-' + i}
          />
        );

        if(imageNode.project_videoid) {
          projectPiece = (
            <ProjectVideo
              videoId={imageNode.project_videoid}
              key={projectDetails.title + '-' + i}
            />
          );
        }

        return (
          projectPiece
        )

      }
    )

  }



  //Refs
  const elements = {
    pageContainer : React.createRef()
  }

  //Event Listeners

  useEffect(() => {

    //Component Will Mount
    document.addEventListener('scroll', handleScroll);

    if(transitionStatus==="entered") {
      elements.pageContainer.current.style.background = "#CFCCBA";
    }

    return () => {
      //Component Will Unmount
      document.removeEventListener('scroll', handleScroll);
    }

  });



  function handleScroll() {

      let pageContainer = elements.pageContainer.current;
      let pageContainerBottom = pageContainer.offsetHeight + pageContainer.scrollTop;
      let windowPosition = window.scrollY;

      if(windowPosition >= pageContainerBottom + 119) {
        turnPageInvisible( () => {triggerReturnToHomePage();});
      }

  }

  function turnPageInvisible(callback) {
    elements.pageContainer.current.style.opacity = 0;
    elements.pageContainer.current.style.display = "none";
    callback();
  }

  function triggerReturnToHomePage() {
    navigate('/', {state : {skipAnimation : true}});
  }

  return (
      <Layout
        transitionStatus={transitionStatus}
        entry={entry}
        exit={exit}
        isInfoPage={false}
        location={location}
        isProjectPage={true}
        location={location}>

      <div style={{background : 'transparent'}} className={cn('projectpage_container')} ref={elements.pageContainer}>

        <motion.div
          className={cn('tinted_background')}
          style={
            {
              opacity:opacity
            }
          }
        />

        <Container>
          <Image
            image={projectDetails.header}
            alt={projectDetails.header_alt}
            className={cn('header_image')}
          />
          <h3 className={cn('header_tag')}>{projectDetails.tag}</h3>
          <h1 className={cn('header_title')}>
            {projectDetails.title}
          </h1>
          <div className={cn('header_description')}>
              <p>
                {projectDescription}
              </p>
          </div>
          <div>
            {projectImages}
          </div>
          <ProjectCredits credits={projectDetails.roles} />
        </Container>

      </div>

    </Layout>
  )
}




export default ProjectPage
