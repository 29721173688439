import * as React from "react"
import PropTypes from 'prop-types'

import classNames from 'classnames/bind'
import * as s from './projectvideo.module.scss'

const cn = classNames.bind(s)

const ProjectVideo = (props) => {

  const videoId = props.videoId;

  const videoSrc = "https://player.vimeo.com/video/" + videoId + "?h=b072e33afd&title=0&byline=0&portrait=0"

  return (
    <div className={cn('project_video')}>
      <div style={
        {
          padding:"56.25% 0 0 0",
          position:"relative"
        }}>
        <iframe
          src={videoSrc}
          title={props.videoId}
          style={ {
            position : "absolute",
            top : 0,
            left : 0,
            width : "100%",
            height : "100%" }
          }
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

ProjectVideo.propTypes = {
  videoId : PropTypes.object.isRequired
}

export default ProjectVideo
